@font-face {
    font-family: "Avenir Next Bold";
    src: url("./fonts/AvenirNext-Bold.ttf");
}

.navBarContainer {
    position: absolute; width: 100%;
    bottom: 5%;
}
.navBar {
    display: flex; justify-content: space-around;
    align-items: center; padding-top: 1%; padding-right: 2%;
}

.navBar-logo {
    height: 105px; width: 100px;
}

.mobilMenu {
    display: none;
}

.navbar-items {
    display: flex; justify-content: space-between;
    align-items: center;
}

.item {
    margin-left: 30px; color: #fff; font-family: 'Avenir Next Bold';
    font-size: 16px; transition: 0.5s;
}
.item1 {
    margin-left: 30px; color: #fff; font-family: 'Avenir Next Bold';
    font-size: 16px; transition: 0.5s;
}
.item1:hover {
    cursor: pointer;
    margin-left: 30px; color: #fff; font-family: 'Avenir Next Bold';
    font-size: 16px; transition: 0.5s;
}

.item:hover {
    color: #a8744d;
    transform: scale(0.95);
    transition: 0.5s;
}

.iconItem {
    font-size: 20px; 
}

.itemsMobile {
    display: none;
}

@media screen and (max-width: 800px) {

    .mobilMenu {
        display: block;
    }
    .logoContainer {
        display: none;
    }
    .navBarContainer {
        position: relative;
    }
    .navBar {
        display: none;
    }
    .itemsMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10%;
    }
    .item {
        font-size: 22px;
        margin-bottom: 5%;
        margin-left: 0px;
    }
    .item1 {
        font-size: 18px;
        margin-left: 0px;
        margin-top: 40%;
    }

}