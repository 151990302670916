@font-face {
    font-family: "AntipastoPro";
    src: url("./fonts/AntipastoPro_trial.ttf");
}

.collabText {
    font-family: 'AntipastoPro'; margin: 0%; letter-spacing: -3px;
    font-size: 56px; color: #fff; margin-left: 10px;
}

.logANdCollabContainer {
    display: flex; align-items: center;
    position: absolute; top: 12%;
}

.imgItems {
    margin-top: 5%;
}

.content {
    position: fixed;
    background-color: #a8744d86;
    height: 100%;
    width: 100%;
}
.container1 {
    width: 100%;
    display: flex; z-index: 1;
    justify-content: center; align-items: center;
    padding-bottom: 2%; padding-top: 4%;
}

.text1 {
    color: #fff; text-align: center;
    margin: 0%; font-size: 40px; font-family: 'Avenir Next Heavy';
}

.downloadContainer {
    margin-left: 5%;
    display: flex; flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dowloadInfos {
    display: flex; flex-direction: column;
    align-items: center; justify-content: center;
}

.navBar-logo {
    height: 105px; width: 100px;
}

#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
}

.containerMobile {
    display: none;
}

@media screen and (max-width: 800px) {

    .content {
        position: absolute;
        height: auto;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('https://firebasestorage.googleapis.com/v0/b/collab-shooting.appspot.com/o/palm_brown.png?alt=media&token=bf1e766a-973e-4b5a-bdb8-5cfedb2d0b58');
    }
    #myVideo {
        height: auto;
        display: none;
    }
    .container1 {
        display: none;
    }
    .containerMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .logANdCollabContainer {
        position: relative; margin-top: 10%;
        margin-bottom: -5%;
    }
    .imgItems {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 10%;
        margin-bottom: 10%;
    }

}