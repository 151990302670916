@font-face {
    font-family: "Avenir Next Bold";
    src: url("./fonts/AvenirNext-Bold.ttf");
}
@font-face {
    font-family: "Avenir Next Heavy";
    src: url("./fonts/AvenirNext-Heavy.ttf");
}
@font-face {
    font-family: "Avenir Next";
    src: url("./fonts/AvenirNext-Medium.ttf");
}

.containerHeadHowItWork {
    background-color: #a8744d;
    height: 30vh; display: flex; justify-content: center;
    align-items: center; margin-bottom: 12%;
}
.containerHeadHowItWork p {
    font-size: 70px; margin: 0%;
}

.logoPosition {
    width: 130px; z-index: 1;
    position: absolute; top: 4%;
    left: 25%;
}

.logoHello {
    margin-left: 16%;
    margin-bottom: 5%;
}

.title{
    text-align: center; font-family: 'Avenir Next Heavy';
    margin-bottom: 5%; margin-top: -1.5%;
}

.questionContainer {
    width: 70%;
    margin-bottom: 5%;
}

.question {
    margin: 0%; font-family: 'Avenir Next Bold';
    font-size: 30px; margin-bottom: 1%; color: #4A4A4A;
}

.response {
    margin: 0%; font-family: 'Avenir Next'; background-color: #f9f9fa;
    font-size: 18px; text-align: justify; color: #4A4A4A; padding: 30px;
}

.endText {
    margin: 0%; font-family: 'Avenir Next Heavy'; margin-top: 5%;
    font-size: 20px; padding-bottom: 5%; text-align: center;
}

.copyRight {
    margin: 0%; font-family: 'Avenir Next Bold'; margin-top: 5%;
    font-size: 12px; padding-bottom: 5%; text-align: center; color: #4A4A4A;
}

.strongHeavy {
    font-family: 'Avenir Next Bold';
}

@media screen and (max-width: 800px) {

    .questionContainer {
        width: 90%;
    }

    .title{
        margin-bottom: 10%;
    }

    .containerHeadHowItWork {
        background-color: #a8744d;
        height: 20vh; display: flex; justify-content: center;
        align-items: center; margin-bottom: 25%;
    }
    .containerHeadHowItWork p {
        font-size: 50px; margin: 0%;
    }
    
    .logoPosition {
        width: 80px; z-index: 1;
        position: absolute; top: 2.5%;
        left: 15%;
    }

    .logoHello {
        margin-left: 10%;
        width: 250px;
        margin-bottom: 7%;
    }

}